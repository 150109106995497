<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 min-h-screen">
    
    <div v-if="data" class="py-8">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Profile</h3>
            <p class="mt-1 text-sm text-gray-600">
              This information is used througout the portal.
            </p>
            <h3 class="mt-4">
              <strong>{{data.first_name}} {{data.last_name}}</strong>
            </h3>
            <SignoutButton />
            
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit.prevent="save" method="POST">
            <div class="shadow sm:rounded-md">
              <div class="px-4 py-5 bg-white space-y-6 sm:p-6">

                <div>
                  <div class="col-span-6 sm:col-span-6">
                    <label for="phone" class="block text-sm font-medium text-gray-700">Mobiel nummer</label>
                    <input type="text" name="phone" id="phone" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="data.phone" placeholder="Best format to use: 0031612345678">
                    <p class="mt-2 text-sm text-gray-500">
                      Zodat je collega's je kunnen bereiken via bijvoorbeeld het smoelenboek.
                    </p>
                  </div>
                  
                </div>

                <div class="col-span-6 sm:col-span-6">
                  <label for="description" class="block text-sm font-medium text-gray-700">A short introduction for others to get to know you a bit more.</label>
                  <textarea name="description" id="description" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="data.internal_intro"></textarea>
                </div>

                <!--<div>
                  <label class="block text-sm font-medium text-gray-700">
                    Photo
                  </label>
                  <div class="mt-1 flex items-center">
                    <span class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                      <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                    <button type="button" class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                      Change
                    </button>
                  </div>
                </div>-->

                <div class="col-span-6 sm:col-span-3">
                  <label for="tags" class="block text-sm font-medium text-gray-700">Business Unit(s)</label>
                  <Multiselect
                    v-model="selectedBusinessUnits"
                    mode="tags"
                    :options="allBusinessUnits"
                    :searchable="true"
                    :classes="{
                      container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none',
                      containerDisabled: 'cursor-default bg-gray-100',
                      containerOpen: 'rounded-b-none',
                      containerOpenTop: 'rounded-t-none',
                      containerActive: 'ring ring-blue-500 ring-opacity-30',
                      singleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                      multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                      search: 'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
                      tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2',
                      tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap',
                      tagDisabled: 'pr-2 !bg-gray-400 text-white',
                      tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
                      tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
                      tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
                      tagsSearch: 'absolute inset-0 border-0 outline-none appearance-none p-0 text-base font-sans box-border w-full',
                      tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
                      placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
                      caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
                      caretOpen: 'rotate-180 pointer-events-auto',
                      clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
                      clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                      spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
                      dropdown: 'absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
                      dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
                      dropdownHidden: 'hidden',
                      options: 'flex flex-col p-0 m-0 list-none',
                      optionsTop: 'flex-col-reverse',
                      option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
                      optionPointed: 'text-gray-800 bg-gray-100',
                      optionSelected: 'text-white bg-blue-500',
                      optionDisabled: 'text-gray-300 cursor-not-allowed',
                      optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
                      optionSelectedDisabled: 'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
                      noOptions: 'py-2 px-3 text-gray-600 bg-white',
                      noResults: 'py-2 px-3 text-gray-600 bg-white',
                      fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
                      spacer: 'h-9 py-px box-content',
                    }"
                  />
                </div>

              </div>

              
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                  <svg v-show="saving" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
                  {{saving ? 'saving': 'save'}}
                </button>
              </div>
            </div>

          </form>
        </div>

        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Profiel/CV foto</h3>
            <p class="mt-1 text-sm text-red-600">
              <strong>Deze foto wordt gebruikt binnen de portal en op je CV(!)</strong><br>Upload s.v.p. je McCoy portretfoto. Is die nog niet gemaakt, gebruik dan een representatieve foto van jezelf mits je die hebt.
              (Staan er meerdere foto's geupload? Zorg dat er maar 1 foto staat.)
            </p>
            <h2 class="font-bold py-6">Nog geen McCoy profielfoto/portretfoto?</h2>
            <p>
              <a href="mailto:rutger.heijmerikx@mccoy-partners.com" class="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">Maak een afspraak met de fotograaf!</a>
            </p>
          </div>
        </div>

        <div class="mt-5 md:mt-0 md:col-span-2">
          <div v-if="data">
            
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div>
                      <Uploader model="User" :model_id="data.id" @update-add="updateAttachments" />  
                      <PhotoList model="User" :photos="data.Photos" :id="data.id" @update-delete="updateAttachments" />
                    </div>
                  </div>    
                </div>
              </div>
            
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import PhotoList from '../components/admin/generic/PhotoList';
import Uploader from '../components/admin/generic/Uploader';
import ProfileService from "../services/admin/profile/service";
import PeopleService from '../services/admin/people/service';
import Multiselect from "@vueform/multiselect";
import SignoutButton from '../components/SignoutButton.vue';

export default {
  name: "Profile",
  components: {
    PhotoList,
    Uploader,
    Multiselect,
    SignoutButton
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const saving = ref(false);
    const route = useRoute();
    const otpStatus = ref(null);
    const id = route.params.id;
    const allBusinessUnits = ref(null);
    const selectedBusinessUnits = ref(null);

    const fetchAllBusinessUnits = async function() {
      await PeopleService.getBusinessUnits().then(({data})=>{
        allBusinessUnits.value = data.map((t)=> {
          return {
            value: t.id,
            label: t.title
          }
        })
      });
    }

    function fetchData() {
      loading.value = true;
      return ProfileService.get(id)
        .then((res) => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then((json) => {
          // set the response data
          data.value = json;
          selectedBusinessUnits.value = data.value.BusinessUnits.map((t)=>{return t.id});
        })
        .catch((err) => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then((json) => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }
    
    async function statusOtp() {
      const {data} = await ProfileService.statusOTP();
      otpStatus.value = data.result
    }

    onMounted(() => {
      fetchData();
      statusOtp(); 
      fetchAllBusinessUnits();
    });

    return {
      data,
      loading,
      saving,
      otpStatus,
      allBusinessUnits,
      selectedBusinessUnits,
      error,
    };
  },
  methods: {
    async save() {
      this.saving = true;
      const { data } = await ProfileService.update({phone: this.data.phone, internal_intro: this.data.internal_intro, business_units: this.selectedBusinessUnits});
      this.emitter.emit('notification', {type: 'success', message: 'Updated', description: 'So nice, lekker bezig!'});
      this.data = data;
      this.saving = false;
    },
    async updateAttachments() {
      // todo update attachments only?
      const data = await ProfileService.get(this.$route.params.id);
      this.data = data.data;
    }
  },
};
</script>
