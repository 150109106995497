import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class UploadService {
  getPhotos(modelName, id) {
    return axios.get(API_URL + `admin/uploader/photos?model=${modelName}&id=${id}`, { headers: authHeader() });
  }

  reorderPhotos(list) {
    return axios.post(API_URL + `admin/uploader/photos/reorder`, { list }, { headers: authHeader() });
  }

  getCredentials(filename) {
    return axios.get(API_URL + `admin/uploader/getCredentials?filename=${filename}`, { headers: authHeader() });
  }

  uploadToS3(url, file, options) {
    return axios.put(url, file, options);
  }

  attachmentToModel(data) {
    return axios.post(API_URL + `admin/uploader/attach`, { ...data }, { headers: authHeader() });
  }

  deleteImage(id, image_type) {
    console.log(image_type);
    if (image_type === "image") {
      return axios.delete(API_URL + `admin/uploader/images/${id}`, { headers: authHeader() });
    } else {
      return axios.delete(API_URL + `admin/uploader/photos/${id}`, { headers: authHeader() });
    }
  }

  highlightImage(id) {
    return axios.put(API_URL + `admin/uploader/photos/${id}`, {id}, { headers: authHeader() });
  }

}

export default new UploadService();
