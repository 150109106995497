<template>
  <button v-on:click="logoutRedirect" class="flex place-items-center bg-yellow-300 text-black px-2 py-1 my-2">
    <Logout32 class="h-5 w-5 mr-1" />
    sign out
  </button> 
</template>

<script setup>
import { useMsal } from '../composition-api/useMsal';
import { Logout32 } from '@carbon/icons-vue';

const { instance } = useMsal();

// const logoutPopup = () => {
//   instance.logoutPopup({
//     mainWindowRedirectUri: "/"
//   });
// }

const logoutRedirect = () => {
  instance.logoutRedirect();
}
</script>
