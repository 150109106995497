import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class ProfileService {
  
  get() {
    return axios.get(API_URL + `admin/profile`, { headers: authHeader() });
  }

  update({phone, business_units, internal_intro}) {
    return axios.put(API_URL + `admin/profile`, {phone, internal_intro, business_units}, { headers: authHeader() });
  }

  statusOTP() {
    return axios.get(API_URL + `admin/profile/status-otp`, { headers: authHeader() });
  }
  
  setupOTP() {
    return axios.get(API_URL + `admin/profile/setup`, { headers: authHeader() });
  }

  validateOTP(code) {
    return axios.post(API_URL + `admin/profile/validate-otp`, {code}, { headers: authHeader() });
  }

  loginOTP(code) {
    return axios.post(API_URL + `admin/profile/login-otp`, {code}, { headers: authHeader() });
  }

}

export default new ProfileService();
